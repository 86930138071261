import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as Colors from '../theme/colors'
import SideMenuToggleButton from "./sideMenu/sideMenuToggleButton";
import './header.scss'

const Header = ({ siteTitle, toggleMenu, menuLinks }) => (
  <header
    style={{
      background: Colors.Main,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <nav className="header-navigation">
        <div className="btn-side-menu-toggle">
          <SideMenuToggleButton toggleMenu={toggleMenu} />
        </div>
        <div className="header-logo">
          <Link to={"/"}>{siteTitle}</Link>
        </div>
        <div className="spacer"></div>
        <div className="header-nav-items">
          <ul>
            {menuLinks.map(link =>
                <li>
              <Link to={link.link}>{link.name}</Link>
            </li>
              )}
          </ul>
        </div>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
