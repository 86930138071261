/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.scss"
import {useState} from "react";
import Backdrop from "./backdrop/backdrop"
import SideMenu from "./sideMenu/sideMenu";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
              name
              link
          }  
        }    
      }
    }
  `)

  const [toggleSideMenu, setToggleSideMenu] = useState(false)

  const handleSideMenuClick = () => {
    setToggleSideMenu(!toggleSideMenu)
  }

  const handleBackdropClick = () => {
    setToggleSideMenu(false)
  }

  return (
    <>
      {toggleSideMenu ? <Backdrop handleClick={handleBackdropClick} /> : null}
      <Header menuLinks={data.site.siteMetadata.menuLinks} toggleMenu={handleSideMenuClick} siteTitle={data.site.siteMetadata?.title || `Title`} />
      <SideMenu sideMenuOpen={toggleSideMenu} menuLinks={data.site.siteMetadata.menuLinks} />
        <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          © {new Date().getFullYear()},
          {` `}
          <a href="https://www.gatsbyjs.com">Gijs Rietveldt</a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
