// https://coolors.co/palette/f4f1de-e07a5f-3d405b-81b29a-f2cc8f

const Main = '#f37736'

const Danger = 'red'

export {
    Main,
    Danger
}
