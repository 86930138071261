import React from "react"
import { Link } from "gatsby"
import "./sideMenu.scss"

const SideMenu = ({ sideMenuOpen, menuLinks }) => {
  let menuClasses = "side-menu"
  if (sideMenuOpen) {
    menuClasses = "side-menu open"
  }
  return (
    <nav className={menuClasses}>
      <ul>
        {menuLinks.map(link =>
        <li>
          <Link to={link.link}>{link.name}</Link>
        </li>
        )}
      </ul>
    </nav>
  )
}
export default SideMenu
